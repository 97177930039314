import {
  checkFirstLR,
  checkSecondLR,
  hideLoader,
  setCustomFitStep,
  showLoader
} from 'modules/fitting/store/fittingActions/fittingActions';
import React, { useContext, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isIOS, isSafari } from 'react-device-detect';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container } from 'reactstrap';
import { MatchDetectionEarImageDetails } from 'services/detectionService/detectionCommand/detectionCommand';
import MultipointMatchDetectionEarImageDetails from 'services/detectionService/detectionCommand/MultipointMatchDetectionEarImageDetails';
import detectionService from 'services/detectionService/detectionService';
import orderService from 'services/orderService/orderService';
import { OrderPhotosDetails } from 'services/orderService/orderServiceCommand/orderServiceCommand';
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount';
import 'styles/Configurator.scss';
import { Identity } from 'typings/types';
import FittingContext from '../../store/FittingContext/FittingContext';
import { EarSide } from '../../store/storeModels';
import CapturePhotoButton from '../CapturePhotoButton';
import CustomFitHeading from '../CustomFitHeading';
import OpenInSafariWarning from '../OpenInSafariWarning';
import TopBar from '../TopBar';
import styles from './CustomFitEarPhotos.module.scss';

type CustomFitEarPhotosProps = {
  userDetails?: Identity;
} & RouteComponentProps;

const CustomFitEarPhotos: React.FC<CustomFitEarPhotosProps> = (
  props: CustomFitEarPhotosProps
) => {
  const { history } = props;
  const {
    store: {
      firstEarType,
      firstPointDetectResponse,
      firstMultiPointDetectResponse,
      secondPointDetectResponse,
      secondMultiPointDetectResponse,
      cardNumber,
      accessCodeCart,
      firstPointStatus,
      firstMultiPointStatus,
      secondPointStatus,
      secondMultiPointStatus,
      secondImgData,
      firstImgData
    },
    dispatch
  } = useContext(FittingContext);
  const [sendPhotosError, setSendPhotosError] = useState<string>('');

  useEffect(() => {
    const checkMatchDetection = async (): Promise<void> => {
      const left_ear_fname =
        firstEarType === EarSide.Left
          ? firstPointDetectResponse.file_name
          : secondPointDetectResponse.file_name;
      const right_ear_fname =
        firstEarType === EarSide.Right
          ? firstPointDetectResponse.file_name
          : secondPointDetectResponse.file_name;

      const left_multi_ear_fname =
        firstEarType === EarSide.Left
          ? firstMultiPointDetectResponse.file_name
          : secondMultiPointDetectResponse.file_name;
      const right_multi_ear_fname =
        firstEarType === EarSide.Right
          ? firstMultiPointDetectResponse.file_name
          : secondMultiPointDetectResponse.file_name;
      const uuid = /* userDetails?.user_unique_id ?? */ 0; // TODO: temp remove

      const imageData = new MatchDetectionEarImageDetails(
        uuid,
        cardNumber,
        left_ear_fname,
        right_ear_fname
      );

      const multiImageData = new MultipointMatchDetectionEarImageDetails(
        uuid,
        cardNumber,
        left_multi_ear_fname,
        right_multi_ear_fname
      );

      try {
        const [
          { data: matchingAlgo },
          // { data: phoenixAlgo },
          { data: physicalAlgo }
        ] = await Promise.all([
          detectionService.getMatches(imageData),
          // detectionService.getPhoenixMatches(multiImageData),
          detectionService.getPhysicalMatches(multiImageData)
        ]);
        // #region
        // console.log('response:', response);
        // console.log("4");
        // dispatch({
        //     type: MATCH_DETECT,
        //     payload: { "leftMatchResp": finalResp.left, "input": imageData, "rightMatchResp": finalResp.right }
        // })
        // #endregion
      } catch {
        // #region
        // dispatch({
        //   type: ERROR,
        //   payload: { error: 'Error while doing matching detection.' }
        // });
        // #endregion
      }
    };
    if (isIOS) {
      if (!isSafari) {
        confirmAlert({
          customUI: OpenInSafariWarning,
          closeOnClickOutside: false
        });
      }
    }

    if (
      firstPointStatus &&
      secondPointStatus &&
      firstMultiPointStatus &&
      secondMultiPointStatus
    ) {
      checkMatchDetection();
    }
  }, [
    cardNumber,
    firstEarType,
    firstPointDetectResponse.file_name,
    firstPointStatus,
    firstMultiPointStatus,
    secondPointDetectResponse.file_name,
    secondPointStatus,
    secondMultiPointStatus,
    firstMultiPointDetectResponse.file_name,
    secondMultiPointDetectResponse.file_name
  ]);

  const sendPhotosAction = async (
    orderDetails: OrderPhotosDetails
  ): Promise<void> => {
    try {
      const response = await orderService.updateOrder(orderDetails);
      if (response?.data) {
        dispatch(hideLoader());
        dispatch(setCustomFitStep(4));
      }
    } catch (error) {
      setSendPhotosError(
        error?.message ?? 'An error occurred, Please try after sometime.'
      );
      // #region
      // dispatch({ /* only used inside component */
      //   type: SET_SP_ERR,
      //   payload: {
      //     error: error
      //       ? error.message
      //       : 'An error occurred, Please try after sometime.'
      //   }
      // });
      // #endregion
    }
  };

  const sendPhotosClick = (): void => {
    dispatch(showLoader(''));
    const orderPhotoDetails = new OrderPhotosDetails(
      accessCodeCart[0].Cart_id,
      firstEarType === EarSide.Left
        ? firstPointDetectResponse.file_name
        : secondPointDetectResponse.file_name,
      firstEarType === EarSide.Right
        ? firstPointDetectResponse.file_name
        : secondPointDetectResponse.file_name,
      firstEarType === EarSide.Left
        ? firstMultiPointDetectResponse.file_name
        : secondMultiPointDetectResponse.file_name,
      firstEarType === EarSide.Right
        ? firstMultiPointDetectResponse.file_name
        : secondMultiPointDetectResponse.file_name
    );
    sendPhotosAction(orderPhotoDetails);
  };

  const handleBackClick = (): void => {
    dispatch(setCustomFitStep(2));
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Container
        fluid
        className="pl-0 pr-0"
        style={{ minHeight: window.innerHeight }}
      >
        <div style={{ minHeight: window.innerHeight - 80 }}>
          <TopBar
            onBackClick={handleBackClick}
            hasLeftButton
            leftButtonTitle="HELP"
            onLeftButtonClick={() => history.push('/instructions')}
          />
          <Col className={`cfEarphotosOuterDiv ${styles.mainBody}`}>
            <CustomFitHeading title="Take a photo of each ear" />
            <div className={`cfEPhOuterDiv ${styles.mt}`}>
              <div className="cfEPhInnerDiv cfEPhInnerLeftDiv">
                <CapturePhotoButton
                  imageData={firstImgData}
                  detectionStatus={firstPointStatus}
                  title="Left Ear"
                  onCaptureClick={(): void => dispatch(checkFirstLR())}
                />
              </div>
              <div className="cfEPhInnerDiv cfEPhInnerRightDiv">
                <CapturePhotoButton
                  imageData={secondImgData}
                  detectionStatus={secondPointStatus}
                  title="Right Ear"
                  onCaptureClick={(): void => dispatch(checkSecondLR())}
                />
              </div>
            </div>

            <Col className="sixDigiterrDiv">{sendPhotosError}</Col>
          </Col>
        </div>
        <Col className={`text-center ${styles.btnContainer}`}>
          {firstPointStatus && secondPointStatus ? (
            <Button
              className={styles.letsGoBtn}
              onClick={() => sendPhotosClick()}
            >
              Send Photos
            </Button>
          ) : (
            <Button className={`${styles.letsGoBtn} opacity-03`}>
              Send Photos
            </Button>
          )}
        </Col>
      </Container>
    </>
  );
};

export default CustomFitEarPhotos;
